import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/reports/offers'
export default {
  namespaced: true,
  state: {
    details: [],
    dataCount: 0,
    summary: [],
    statuses: {
      data: [],
      chart: {
        categories: [],
        series: [],
      },
    },
    products: {
      data: [],
    },
    filter: {
      sdate: null,
      edate: null,
      id_users: null,
      limit: 25,
      start: 0,
    },
  },
  getters: {
    filterParams(state) {
      return state.filter
    },
    details(state) {
      return state.details
    },
    dataCount(state) {
      return state.dataCount
    },
    summary(state) {
      return state.summary
    },
    statuses(state) {
      return state.statuses
    },
    products(state) {
      return state.products
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.summary = data.summary
      state.statuses = data.statuses
      state.products = data.products
    },
    SET_REPORT_DETAIL(state, data) {
      state.details = data.data
      state.dataCount = data.count
    },
  },
  actions: {
    getReport({ commit }, data) {
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    getDetail({ commit }, data) {
      axiosIns
        .post(`${ROOT_URL}/details`, data)
        .then(response => {
          commit('SET_REPORT_DETAIL', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
