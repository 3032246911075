export default [
  {
    path: '/app/customers/accounts',
    name: 'CustomerAccounts',
    component: () => import('@/views/Admin/Customer_accounts/Index.vue'),
    meta: {
      pageTitle: 'Müşteri Hesapları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_ACCOUNT_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/customers/accounts/customer-search',
    name: 'AccountsCustomerSearch',
    component: () => import('@/views/Admin/Customer_accounts/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Müşteri Hesapları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/customers/accounts',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_ACCOUNT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/customers/accounts/add/:id_customers',
    name: 'CustomerAccountsAdd',
    component: () => import('@/views/Admin/Customer_accounts/Add.vue'),
    meta: {
      pageTitle: 'Müşteri Hesapları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/customers/accounts',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/app/customers/accounts/customer-search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_ACCOUNT_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/customers/accounts/edit/:id',
    name: 'CustomerAccountsEdit',
    component: () => import('@/views/Admin/Customer_accounts/Edit.vue'),
    meta: {
      pageTitle: 'Müşteri Hesapları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/customers/accounts',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_ACCOUNT_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/customers/accounts/requests',
    name: 'RequestAccounts',
    component: () => import('@/views/Admin/Customer_accounts/Requests/Index.vue'),
    meta: {
      pageTitle: 'Müşteri Hesapları',
      breadcrumb: [
        {
          text: 'Hesap Başvuruları',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_ACCOUNT_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/app/customers/accounts/requests/view/:id',
    name: 'RequestAccountView',
    component: () => import('@/views/Admin/Customer_accounts/Requests/View.vue'),
    meta: {
      pageTitle: 'Müşteri Hesapları',
      breadcrumb: [
        {
          text: 'Hesap Başvuruları',
          to: '/app/customers/accounts/requests',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_CUSTOMER_ACCOUNT_REQUESTS',
      action: 'read',
    },
  },
]
