export default [
  {
    path: '/admin/defines/offer-statuses',
    name: 'OfferStatuses',
    component: () => import('@/views/Admin/Defines/Offer_statuses/Index.vue'),
    meta: {
      pageTitle: 'Teklif Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/offer-statuses/add',
    name: 'OfferStatusAdd',
    component: () => import('@/views/Admin/Defines/Offer_statuses/Add.vue'),
    meta: {
      pageTitle: 'Teklif Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/offer-statuses',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/offer-statuses/edit/:id',
    name: 'OfferStatusEdit',
    component: () => import('@/views/Admin/Defines/Offer_statuses/Edit.vue'),
    meta: {
      pageTitle: 'Teklif Durumları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/offer-statuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
