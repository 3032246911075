export default [
  {
    path: '/admin/defines/activities',
    name: 'Activities',
    component: () => import('@/views/Admin/Defines/Activities/Index.vue'),
    meta: {
      pageTitle: 'Faaliyet Alanları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/activities/add',
    name: 'ActivityAdd',
    component: () => import('@/views/Admin/Defines/Activities/Add.vue'),
    meta: {
      pageTitle: 'Faaliyet Alanları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/activities',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/activities/edit/:id',
    name: 'ActivityEdit',
    component: () => import('@/views/Admin/Defines/Activities/Edit.vue'),
    meta: {
      pageTitle: 'Faaliyet Alanları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/activities',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
