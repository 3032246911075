import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/catalog_privates'
export default {
  namespaced: true,
  state: {
    dataList: [],
    filterParams: {
      keyword: null,
    },
    modalStatus: { status: false },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    filterParams(state) {
      return state.filterParams
    },
    modalStatus(state) {
      return state.modalStatus
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_MODAL_STATUS(state, data) {
      state.modalStatus = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
