import Vue from 'vue'
import Vuex from 'vuex'

/* Admin:Core */
import smtpConfig from '@/store/Admin/Core/smtpconfig'
import users from '@/store/Admin/Core/users'
import userTypes from '@/store/Admin/Core/user_types'
import abilities from '@/store/Admin/Core/abilities'
import cropperUploads from '@/store/Admin/Core/cropper_uploads'
import multipleUploads from '@/store/Admin/Core/multiple_uploads'
import configCompany from '@/store/Admin/Core/config_company'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
/* Admin:Defines */
import countries from './Admin/Defines/countries'
import cities from './Admin/Defines/cities'
import currencies from './Admin/Defines/currencies'
import customerTypes from './Admin/Defines/customer_types'
import catalogStatuses from './Admin/Defines/catalog_statuses'
import catalogTypes from './Admin/Defines/catalog_types'
import interviewStatuses from './Admin/Defines/interview_statuses'
import interviewSubjects from './Admin/Defines/interview_subjects'
import meets from './Admin/Defines/meets'
import offerStatuses from './Admin/Defines/offer_statuses'
import offerTerms from './Admin/Defines/offer_terms'
import orderTerms from './Admin/Defines/order_terms'
import orderStatuses from './Admin/Defines/order_statuses'
import sectors from './Admin/Defines/sectors'
import taxes from './Admin/Defines/taxes'
import units from './Admin/Defines/units'
import activities from './Admin/Defines/activities'
import relatedPersonTypes from './Admin/Defines/related_person_types'
import socialMediaNetworks from './Admin/Defines/socialmedia_networks'
/* Admin:App */
import baskets from './Admin/App/baskets'
import chatMessages from './Admin/App/chat_messages'
import carousel from './Admin/App/carousel'
import catalogs from './Admin/App/catalogs'
import catalogTracings from './Admin/App/catalog_tracings'
import catalogTracingLines from './Admin/App/catalog_tracing_lines'
import catalogPrivates from './Admin/App/catalog_privates'
import customers from './Admin/App/customers'
import customerAddresses from './Admin/App/customer_addresses'
import customerAccounts from './Admin/App/customer_accounts'
import customerRelatedPersons from './Admin/App/customer_related_persons'
import customerSocialMedia from './Admin/App/customer_socialmedia'
import customerSectors from './Admin/App/customer_sectors'
import customerActivities from './Admin/App/customer_activities'
import customerHistory from './Admin/App/customer_history'
import dashboard from './Admin/App/dashboard'
import productGroups from './Admin/App/product_groups'
import productMedia from './Admin/App/product_media'
import products from './Admin/App/products'
import interviews from './Admin/App/interviews'
import interviewFiles from './Admin/App/interview_files'
import reminders from './Admin/App/reminders'
import offers from './Admin/App/offers'
import orders from './Admin/App/orders'
import pushNotifications from './Admin/App/push_notifications'
import registerRequests from './Admin/App/register_requests'
/* Admin:Reports */
import customerReports from './Admin/Reports/customerReports'
import interviewReports from './Admin/Reports/interviewReports'
import offerReports from './Admin/Reports/offerReports'
import catalogReports from './Admin/Reports/catalogReports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    smtpConfig,
    users,
    userTypes,
    abilities,
    cropperUploads,
    multipleUploads,
    configCompany,
    countries,
    cities,
    currencies,
    customerTypes,
    catalogStatuses,
    catalogTypes,
    interviewStatuses,
    interviewSubjects,
    meets,
    offerStatuses,
    offerTerms,
    orderTerms,
    orderStatuses,
    sectors,
    taxes,
    units,
    activities,
    relatedPersonTypes,
    socialMediaNetworks,
    baskets,
    chatMessages,
    carousel,
    catalogs,
    catalogTracings,
    catalogTracingLines,
    catalogPrivates,
    customers,
    customerAddresses,
    customerAccounts,
    customerRelatedPersons,
    customerSocialMedia,
    customerSectors,
    customerActivities,
    customerHistory,
    dashboard,
    productGroups,
    productMedia,
    products,
    interviews,
    interviewFiles,
    reminders,
    offers,
    orders,
    pushNotifications,
    customerReports,
    interviewReports,
    offerReports,
    catalogReports,
    registerRequests,
  },
  strict: process.env.DEV,
})
