export default [
  {
    path: '/app/catalogs',
    name: 'Catalogs',
    component: () => import('@/views/Admin/Catalogs/Index.vue'),
    meta: {
      pageTitle: 'Kataloglar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CATALOGS_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/catalogs/add',
    name: 'CatalogAdd',
    component: () => import('@/views/Admin/Catalogs/Add.vue'),
    meta: {
      pageTitle: 'Kataloglar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/catalogs',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CATALOGS_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/catalogs/edit/:id',
    name: 'CatalogEdit',
    component: () => import('@/views/Admin/Catalogs/Edit.vue'),
    meta: {
      pageTitle: 'Kataloglar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/catalogs',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CATALOGS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/app/catalogs/view/:id',
    name: 'CatalogView',
    component: () => import('@/views/Admin/Catalogs/View.vue'),
    meta: {
      pageTitle: 'Kataloglar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/catalogs',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_CATALOGS_VIEW',
      action: 'read',
    },
  },
]
