import axiosIns from '@/libs/axios'
import moment from 'moment'

const ROOT_URL = '/Admin/data/dashboard'
export default {
  namespaced: true,
  state: {
    dashboard: {
      counters: {
        customers: null,
        interviews: null,
        offers: null,
        catalogs: null,
        offerRequests: null,
        registerRequests: null,
      },
      interviews: [],
      reminders: [],
      offers: [],
      catalogs: [],
      offerRequests: [],
    },
    filters: {
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    loading: false,
  },
  getters: {
    dashboard(state) {
      return state.dashboard
    },
    loading(state) {
      return state.loading
    },
    filters(state) {
      return state.filters
    },
  },
  mutations: {
    SET_DASHBOARD(state, data) {
      state.dashboard = data
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
    RESET_DASHBOARD(state) {
      state.dashboard = {
        counters: {
          customers: null,
          interviews: null,
          offers: null,
          services: null,
        },
        interviews: [],
        reminders: [],
        services: [],
      }
    },
  },
  actions: {
    getData({ commit }, params) {
      commit('SET_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DASHBOARD', response.data.data)
          commit('SET_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
