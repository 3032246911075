export default [
  {
    path: '/admin/defines/units',
    name: 'Units',
    component: () => import('@/views/Admin/Defines/Units/Index.vue'),
    meta: {
      pageTitle: 'Birim Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/units/add',
    name: 'UnitAdd',
    component: () => import('@/views/Admin/Defines/Units/Add.vue'),
    meta: {
      pageTitle: 'Birim Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/units',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/units/edit/:id',
    name: 'UnitEdit',
    component: () => import('@/views/Admin/Defines/Units/Edit.vue'),
    meta: {
      pageTitle: 'Birim Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/units',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
