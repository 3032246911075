export default [
  {
    path: '/app/messages',
    name: 'Messages',
    component: () => import('@/views/Admin/Messages/Index.vue'),
    meta: {
      pageTitle: 'Mesajlar',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_MESSAGES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/messages/archive',
    name: 'MessageArchive',
    component: () => import('@/views/Admin/Messages/Archive.vue'),
    meta: {
      pageTitle: 'Mesajlar',
      breadcrumb: [
        {
          text: 'Arşiv',
          active: true,
        },
      ],
      resource: 'ADMIN_MESSAGES_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/messages/view/:room_code',
    name: 'MessageEdit',
    component: () => import('@/views/Admin/Messages/View.vue'),
    meta: {
      pageTitle: 'Mesajlar',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/messages',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_MESSAGES_VIEW',
      action: 'read',
    },
  },
]
