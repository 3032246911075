export default [
  {
    path: '/app/product-groups',
    name: 'ProductGroups',
    component: () => import('@/views/Admin/Product_groups/Index.vue'),
    meta: {
      pageTitle: 'Ürün Grupları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_GROUP_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/product-groups/add',
    name: 'ProductGroupAdd',
    component: () => import('@/views/Admin/Product_groups/Add.vue'),
    meta: {
      pageTitle: 'Ürün Grupları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/product-groups',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_GROUP_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/product-groups/edit/:id',
    name: 'ProductGroupEdit',
    component: () => import('@/views/Admin/Product_groups/Edit.vue'),
    meta: {
      pageTitle: 'Ürün Grupları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/product-groups',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PRODUCT_GROUP_EDIT',
      action: 'read',
    },
  },
]
