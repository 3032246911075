export default [
  {
    path: '/admin/config/smtp',
    name: 'Smtp',
    component: () => import('@/views/Admin/Smtp/Index.vue'),
    meta: {
      pageTitle: 'Ayarlar',
      breadcrumb: [
        {
          text: 'SMTP Yapılandırma',
          active: true,
        },
      ],
      resource: 'ADMIN_SMTP_CONFIG',
      action: 'read',
    },
  },
  {
    path: '/admin/config/company',
    name: 'ConfigCompany',
    component: () => import('@/views/Admin/Config_company/Index.vue'),
    meta: {
      pageTitle: 'Ayarlar',
      breadcrumb: [
        {
          text: 'Firma Bilgileri',
          active: true,
        },
      ],
      resource: 'ADMIN_COMPANY_CONFIG',
      action: 'read',
    },
  },
]
