import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/offers'
export default {
  namespaced: true,
  state: {
    dataList: [],
    pushNotificationList: [],
    dataCounts: 0,
    pushNotificationCount: 0,
    dataItem: {
      id: null,
      onumber: null,
      odate: null,
      vdate: null,
      title: null,
      content: null,
      terms: null,
      notes: null,
      revised_num: null,
      id_offers: null,
      id_offer_statuses: null,
      id_interviews: null,
      id_customers: null,
      offer_lines: [],
      customer_account: null,
      confirmed_account: null,
      confirmed: null,
      autoPush: true,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLineSave: {
      message: null,
      status: null,
      id: null,
    },
    search: {
      keyword: null,
    },
    customerSend: {
      to: [],
      message: null,
      modalStatus: false,
      sending: false,
    },
    customerSendResult: {
      message: null,
      status: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLineSaveStatus(state) {
      return state.dataLineSave
    },
    search(state) {
      return state.search
    },
    customerSend(state) {
      return state.customerSend
    },
    customerSendResult(state) {
      return state.customerSendResult
    },
    pushNotificationList(state) {
      return state.pushNotificationList
    },
    pushNotificationCount(state) {
      return state.pushNotificationCount
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LINE_SAVE(state, data) {
      state.dataLineSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        onumber: null,
        odate: null,
        vdate: null,
        title: null,
        content: null,
        terms: null,
        notes: null,
        revised_num: null,
        id_offers: null,
        id_offer_statuses: null,
        id_interviews: null,
        id_customers: null,
        offer_lines: [],
        customer_account: null,
        confirmed_account: null,
        confirmed: null,
        autoPush: true,
      }
    },
    SET_SEND_RESULT(state, data) {
      state.customerSendResult = data
      state.customerSend.sending = false
    },
    SET_PUSH_NOTIFICATION_LIST(state, data) {
      state.pushNotificationList = data
    },
    SET_PUSH_NOTIFICATION_COUNT(state, data) {
      state.pushNotificationCount = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, params) {
      const data = JSON.stringify({
        where: {
          'offers.id': params.id,
        },
        array: false,
        isUpdate: params.isUpdate,
        isRevised: params.isRevised,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataView({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/getOffer`, params)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeLine({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteLine`, { id })
        .then(response => {
          commit('SET_DATA_LINE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    customerSend({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/customerSend`, params)
        .then(response => {
          commit('SET_SEND_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getPushNotificationList({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/pushNotificationList`, params)
        .then(response => {
          commit('SET_PUSH_NOTIFICATION_LIST', response.data.data)
          commit('SET_PUSH_NOTIFICATION_COUNT', response.data.count)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
