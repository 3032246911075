import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/customer_accounts'
export default {
  namespaced: true,
  state: {
    dataList: [],
    authLogs: [],
    dataCounts: 0,
    authLogCounts: 0,
    deviceItem: {
      id: null,
      uuid: null,
      device_info: null,
      id_customer_accounts: null,
      opened: null,
    },
    dataItem: {
      id: null,
      name: null,
      surname: null,
      email: null,
      phone: null,
      phone_region_code: null,
      phone_region_iso2: null,
      password: null,
      id_customers: null,
      email_information: '1',
      email_subject: 'Hesap Bilgileriniz',
      email_body: 'Hesap bilgileriniz',
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    emailControl: {
      status: null,
      id: null,
    },
    filterData: {
      id_sectors: [],
      id_activities: [],
      id_meets: null,
      id_countries: null,
      id_cities: null,
    },
    search: {
      keyword: null,
    },
    detailForm: false,
    detailSearch: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    emailControl(state) {
      return state.emailControl
    },
    detailForm(state) {
      return state.detailForm
    },
    detailSearch(state) {
      return state.detailSearch
    },
    filterData(state) {
      return state.filterData
    },
    search(state) {
      return state.search
    },
    authLogs(state) {
      return state.authLogs
    },
    authLogCounts(state) {
      return state.authLogCounts
    },
    deviceItem(state) {
      return state.deviceItem
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_AUTH_LOGS(state, data) {
      state.authLogs = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_AUTH_LOG_COUNTS(state, data) {
      state.authLogCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_EMAIL_CONTROL(state, data) {
      state.emailControl = data
    },
    SET_DETAIL_FORM(state, data) {
      state.detailForm = data
    },
    SET_DEVICE_ITEM(state, data) {
      if (data) {
        state.deviceItem = data
      } else {
        state.deviceItem = {
          id: null,
          uuid: null,
          device_info: null,
          id_customer_accounts: null,
          opened: null,
        }
      }
    },
    SET_DETAIL_SEARCH(state, data) {
      state.detailSearch = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        phone_region_code: null,
        phone_region_iso2: null,
        password: null,
        id_customers: null,
        email_information: '1',
        email_subject: 'Hesap Bilgileriniz',
        email_body: 'Hesap bilgileriniz',
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getAuthLogs({ commit }, params) {
      axiosIns
        .post(`${ROOT_URL}/authLogs`, params)
        .then(response => {
          commit('SET_AUTH_LOGS', response.data.data)
          commit('SET_DEVICE_ITEM', response.data.device)
          commit('SET_AUTH_LOG_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'customer_accounts.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    /* Custom */
    emailControl({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/emailControl`, params)
        .then(response => {
          commit('SET_EMAIL_CONTROL', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    resetDevice({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/resetDevice`, params)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
