import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/catalog_tracings'
export default {
  namespaced: true,
  state: {
    dataList: {
      customers: [],
      summary: [],
      details: [],
      likes: [],
    },
    detailList: [],
    detailLoading: false,
    filterParams: {
      id_customers: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    detailList(state) {
      return state.detailList
    },
    detailLoading(state) {
      return state.detailLoading
    },
    filterParams(state) {
      return state.filterParams
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DETAIL_LIST(state, data) {
      state.detailList = data
    },
    SET_DETAIL_LOADING(state, data) {
      state.detailLoading = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDetailList({ commit }, params) {
      commit('SET_DETAIL_LOADING', true)
      const data = JSON.stringify(params)
      return axiosIns
        .post(`${ROOT_URL}/detail`, data)
        .then(response => {
          commit('SET_DETAIL_LIST', response.data.data)
          commit('SET_DETAIL_LOADING', false)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}
