export default [
  {
    path: '/app/carousel',
    name: 'Carousel',
    component: () => import('@/views/Admin/Carousel/Index.vue'),
    meta: {
      pageTitle: 'Slide Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_CAROUSEL_LIST',
      action: 'read',
    },
  },
  {
    path: '/app/carousel/add',
    name: 'CarouselAdd',
    component: () => import('@/views/Admin/Carousel/Add.vue'),
    meta: {
      pageTitle: 'Slide Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/carousel',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_CAROUSEL_ADD',
      action: 'read',
    },
  },
  {
    path: '/app/carousel/edit/:id',
    name: 'CarouselEdit',
    component: () => import('@/views/Admin/Carousel/Edit.vue'),
    meta: {
      pageTitle: 'Slide Ürünler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/app/carousel',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_CAROUSEL_EDIT',
      action: 'read',
    },
  },
]
